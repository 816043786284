
































import Vue from 'vue';
export default Vue.extend({
  name: 'SingleTermInput',
  props: {
    label: {
      type: String,
    },
    value: {
      type: Number,
    },
    required: {
      type: Boolean,
      default: false,
    },
    endedAtLabel: {
      type: String,
      // default: '종료',
    },
    labelColsLg: {
      type: String,
      default: '2',
    },
    isStartedAt: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'date',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    endOfDay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      endedAt: '',
    };
  },
  computed: {
    prependHtml(): string | null {
      if (this.endedAtLabel) {
        return `<small>${this.endedAtLabel}</small>`;
      } else {
        return null;
      }
    },
  },
  created() {
    if (this.value) {
      const endedAt = new Date(this.value);
      this.endedAt =
        this.type === 'date'
          ? this.$moment(endedAt).format('YYYY-MM-DD')
          : this.$moment(endedAt).format('YYYY-MM-DDTHH:mm:ss');
    }
  },
  methods: {
    setInstant() {
      const date = new Date(this.endedAt);
      let term = date.getTime();
      if (!this.isStartedAt && this.type === 'date') {
        term = this.endOfDay
          ? this.$moment(date)
              .endOf('day')
              .subtract('999', 'millisecond')
              .valueOf()
          : this.$moment(date).startOf('day').valueOf();
      }

      this.$emit('input', term);
    },
  },
});
