import { NormalizedCacheObject } from 'apollo-boost';
import ApolloClient from 'apollo-client';
import {
  CreateBundleParams,
  CreateSerialPointPolicyParams,
  UpdateBundleParams,
} from '../model/serial';
import {
  CREATE_BUNDLE_MUTATION,
  CREATE_POLICY_MUTATION,
  DEACTIVATE_BUNDLE_MUTATION,
  DEACTIVATE_POLICY_MUTATION,
  DELETE_SERIALS_MUTATION,
  EXPORT_SERIAL_MUTATION,
  UPDATE_BUNDLE_MUTATION,
} from '../queries/mutation';
import { apolloClient } from '@/apolloClient';
import { DOWNLOAD_URL_QUERY } from '../queries/query';

class SerialService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async createBundle(param: CreateBundleParams): Promise<void> {
    await this.apollo.mutate({
      mutation: CREATE_BUNDLE_MUTATION,
      variables: {
        param,
      },
    });
  }

  async updateBundle(param: UpdateBundleParams): Promise<void> {
    await this.apollo.mutate({
      mutation: UPDATE_BUNDLE_MUTATION,
      variables: {
        param,
      },
    });
  }

  async deactivateBundle(id: string): Promise<void> {
    await this.apollo.mutate({
      mutation: DEACTIVATE_BUNDLE_MUTATION,
      variables: {
        id,
      },
    });
  }

  async createPolicy(param: CreateSerialPointPolicyParams): Promise<void> {
    await this.apollo.mutate({
      mutation: CREATE_POLICY_MUTATION,
      variables: {
        param,
      },
    });
  }

  async deactivatePolicy(id: string): Promise<void> {
    await this.apollo.mutate({
      mutation: DEACTIVATE_POLICY_MUTATION,
      variables: {
        id,
      },
    });
  }

  async exportSerials(id: string): Promise<void> {
    const res = await this.apollo.mutate({
      mutation: EXPORT_SERIAL_MUTATION,
      variables: {
        id,
      },
    });
    console.log(res);
  }

  async downloadFile(id: string): Promise<string> {
    const res = await this.apollo.query({
      query: DOWNLOAD_URL_QUERY,
      variables: {
        id,
      },
    });

    return res.data?.serial.serialFileDownloadUrl;
  }

  async deleteSerials(serialNos: string[]): Promise<any> {
    const response = await this.apollo.mutate({
      mutation: DELETE_SERIALS_MUTATION,
      variables: {
        serialNos,
      },
    });
    const failedSerials: string[] = [];
    response.data.deleteSerials.map((item: { serialNo: any }) =>
      failedSerials.push(item.serialNo as string)
    );
    return failedSerials;
  }
}

export const serialService = new SerialService(apolloClient);
