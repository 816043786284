





























































































import Vue from 'vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import InlineRadioForm from '@/components/Forms/InlineRadioInput.vue';
import SingleTermInput from '@/components/Forms/SingleTermInput.vue';
import TermInput from '@/components/Forms/TermInput.vue';

export default Vue.extend({
  name: 'PointPolicyForm',
  components: {
    InlineInput,
    InlineRadioForm,
    SingleTermInput,
    TermInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCreated: false,
      policyId: 0,
      form: {
        name: '',
        description: '',
        point: 0,
        type: 'POINT',
        expirationMethod: 'DAYS',
        expiresAfter: null,
        expiresAt: null,
        bonusPoint: null,
        usablePeriod: {
          startedAt: '',
          endedAt: '',
        },
      },
      typeOptions: [
        {
          text: '기본형',
          value: 'POINT',
        },
        {
          text: '기업 제휴형',
          value: 'POINT_B2B',
        },
      ],
      expirationMethodOptions: [
        {
          text: '등록일로부터 유효기간 지정',
          value: 'DAYS',
        },
        {
          text: '만료일 지정',
          value: 'UNTIL',
        },
      ],
    };
  },
  watch: {
    'form.type': {
      handler(newVal) {
        if (newVal === 'POINT') {
          this.expirationMethodOptions = [
            {
              text: '등록일로부터 유효기간 지정',
              value: 'DAYS',
            },
            {
              text: '만료일 지정',
              value: 'UNTIL',
            },
          ];

          this.form.expirationMethod = 'DAYS';
        } else {
          this.expirationMethodOptions = [
            {
              text: '만료일 지정',
              value: 'UNTIL',
            },
          ];

          this.form.expirationMethod = 'UNTIL';
          this.form.bonusPoint = null;
        }
      },
    },
  },
  created() {
    console.log('policy value: ', this.value);
    this.form = this.value;
    this.isCreated = this.value.name !== '';
  },
  methods: {
    onInput() {
      this.$emit('input', this.$data.form);
    },
  },
});
