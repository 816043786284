























































































import Vue from 'vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import InlineRadioForm from '@/components/Forms/InlineRadioInput.vue';
import { BundlePolicyTypeId } from '@/domain/serial/model/serial';

export default Vue.extend({
  name: 'SerialBundleForm',
  components: {
    InlineInput,
    InlineRadioForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      marketingAgreementPolicy: null as boolean | null,
      noOrderedUserPolicy: null as boolean | null,
      selectedPlatformPolicies: [] as any[],
      prevSelectedPlatformPolicies: [] as any[],
      form: {
        id: 0,
        title: '',
        description: '',
        type: 'COUPON',
        targetId: '',
        count: 0,
        policyIds: [] as BundlePolicyTypeId[],
      },
      bundleTypeOptions: [
        {
          text: '쿠폰',
          value: 'COUPON',
        },
        {
          text: '에너지',
          value: 'POINT',
        },
      ],
      bundleMarketingAgreementPolicyOptionsOptions: [
        {
          text: '사용',
          value: true,
        },
        {
          text: '미사용',
          value: false,
        },
      ],
      bundlePlatformPolicyOptions: [
        {
          text: 'ios',
          value: BundlePolicyTypeId.PLATFORM_IOS,
        },
        {
          text: 'aos',
          value: BundlePolicyTypeId.PLATFORM_AOS,
        },
        {
          text: 'web',
          value: BundlePolicyTypeId.PLATFORM_WEB,
        },
        {
          text: 'mobile',
          value: BundlePolicyTypeId.PLATFORM_MOBILE,
        },
      ],
      bundleNoOrderedUserPolicyOptions: [
        {
          text: '사용',
          value: true,
        },
        {
          text: '미사용',
          value: false,
        },
      ],
    };
  },
  computed: {
    canEdit(): boolean {
      return this.form.id == 0 || this.form.id == null;
    },
    isAllPlatform: {
      get(): boolean {
        return (
          this.selectedPlatformPolicies.length ===
          this.bundlePlatformPolicyOptions.length
        );
      },
      set(newValue: boolean) {
        if (newValue) {
          this.selectedPlatformPolicies = [
            BundlePolicyTypeId.PLATFORM_AOS,
            BundlePolicyTypeId.PLATFORM_IOS,
            BundlePolicyTypeId.PLATFORM_MOBILE,
            BundlePolicyTypeId.PLATFORM_WEB,
          ];
        } else if (
          this.selectedPlatformPolicies.length ===
          this.bundlePlatformPolicyOptions.length
        ) {
          this.selectedPlatformPolicies = [];
        }
      },
    },
  },
  watch: {
    selectedPlatformPolicies(newVal) {
      if (newVal.length === 0) {
        alert('최소 하나의 플랫폼을 선택해야 합니다.');
        // 배열을 빈 배열로 만든 후 이전 값으로 복원하여 반응형 트리거
        this.$nextTick(() => {
          this.selectedPlatformPolicies = [
            ...this.prevSelectedPlatformPolicies,
          ];
        });
      } else {
        // 현재 값을 이전 값으로 저장
        this.prevSelectedPlatformPolicies = [...newVal];
      }
    },
  },
  created() {
    // 초기 데이터 설정
    this.$data.form = this.value;
    this.initializeFromPolicyIds();
  },
  methods: {
    initializeFromPolicyIds() {
      const policyIds = this.form.policyIds || [];

      // 마케팅 동의 설정
      this.marketingAgreementPolicy = policyIds.includes(
        BundlePolicyTypeId.MARKETING_AGREEMENT_USER
      );

      // 첫 구매 전용 설정
      this.noOrderedUserPolicy = policyIds.includes(
        BundlePolicyTypeId.NO_ORDERED_USER
      );

      const platformIds = policyIds.filter(id => {
        [
          BundlePolicyTypeId.PLATFORM_AOS,
          BundlePolicyTypeId.PLATFORM_IOS,
          BundlePolicyTypeId.PLATFORM_MOBILE,
          BundlePolicyTypeId.PLATFORM_WEB,
        ].includes(id);
      });
      // 플랫폼 설정
      this.selectedPlatformPolicies = platformIds;

      if (platformIds.length == 0) {
        this.selectedPlatformPolicies = [
          BundlePolicyTypeId.PLATFORM_AOS,
          BundlePolicyTypeId.PLATFORM_IOS,
          BundlePolicyTypeId.PLATFORM_MOBILE,
          BundlePolicyTypeId.PLATFORM_WEB,
        ];
      }

      this.prevSelectedPlatformPolicies = this.selectedPlatformPolicies;

      this.isAllPlatform =
        this.selectedPlatformPolicies.length ===
          this.bundlePlatformPolicyOptions.length ||
        this.selectedPlatformPolicies.length == 0;
    },
    onInput() {
      this.updatePolicyIds();
      this.$emit('input', this.$data.form);
    },
    updatePolicyIds() {
      if (this.isAllPlatform == true) {
        this.selectedPlatformPolicies = [
          BundlePolicyTypeId.PLATFORM_AOS,
          BundlePolicyTypeId.PLATFORM_IOS,
          BundlePolicyTypeId.PLATFORM_MOBILE,
          BundlePolicyTypeId.PLATFORM_WEB,
        ];
      }

      let policyIds: BundlePolicyTypeId[] = [];
      // 마케팅 수신 동의 처리
      if (this.marketingAgreementPolicy === true) {
        policyIds.push(BundlePolicyTypeId.MARKETING_AGREEMENT_USER);
      }

      // 첫 구매 전용 추가
      if (this.noOrderedUserPolicy === true) {
        policyIds.push(BundlePolicyTypeId.NO_ORDERED_USER);
      }

      // 플랫폼 정책 추가
      if (!this.isAllPlatform) {
        policyIds.push(
          ...this.selectedPlatformPolicies.filter(policy => policy !== null)
        );
      }

      this.$set(this.$data.form, 'policyIds', [...policyIds]);
    },
  },
});
