var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"notification":"이름을 누르면 시리얼 번들로 이동합니다.","title":"시리얼 번들 리스트","subtitle":_vm.totalRows.toString()},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.size,"align":"center","first-number":"","last-number":""},on:{"page-click":_vm.pageClick},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('b-card-footer',[_c('b-row',{staticClass:"float-right mx-3"},[_c('frip-button',{attrs:{"type":"primary","to":"./create"}},[_vm._v("번들 등록")])],1)],1)]},proxy:true}])},[(_vm.loading > 0)?_c('spinner'):_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center mb-0",staticStyle:{"text-align":"center"},attrs:{"header-row-class-name":"thead-light","header-cell-class-name":"center-table","cell-class-name":"text-center","data":_vm.items}},[_c('el-table-column',{attrs:{"label":"id","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"title","prop":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":("list/" + (row.id))}},[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"label":"종류","prop":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-badge',{attrs:{"type":_vm.typeColor(row.type)}},[_vm._v(" "+_vm._s(row.type)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"지급 정책 ID","prop":"targetId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.targetId)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"시리얼 개수","prop":"count"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.count)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"상태","prop":"state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-badge',{attrs:{"type":_vm.stateColor(row.status)}},[_vm._v(" "+_vm._s(row.status)+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"110px","label":"난수 확인 및 삭제","class-name":"center-table"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticStyle:{"margin-right":"5px"},attrs:{"to":("serials/" + (row.id))}},[_vm._v(" 난수목록 ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }