















































































































import { Table, TableColumn } from 'element-ui';
import { BUNDLE_SERIALS_QUERY } from '../queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { Serial, SerialConnection, SerialEdge } from '../model/serial';
import Spinner from '@/components/Spinner.vue';
import Instant from '@/components/Labels/Instant.vue';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { Connection } from '@frientrip/domain';
import { ModalOption } from '@/frip-alert-modal';
import { parseApiError } from '@/common/ApiErrorParser';
import { serialService } from '@/domain/serial/service/serialService';

export default ContainerMixin.extend({
  name: 'BundleSerialsList',
  components: {
    Instant,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
  },
  data: function () {
    return {
      items: [],
      columns: ['id', 'title', 'type', 'status'],
      loading: 0,
      page: Number(this.$route.query.page || 1),
      size: Number(this.$route.query.size || 20),
      totalRows: 0,
      selection: [] as any[],
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalRows / this.size);
    },
  },
  methods: {
    showAlert(alert: ModalOption) {
      this.$modal.show(alert);
    },
    showErrorAlert(error: any, title?: string) {
      const { message, errors } = parseApiError(error);
      const errorMessages: string[] = [];
      errors.forEach(error => {
        if (!errorMessages.includes(error.message)) {
          errorMessages.push(error.message);
        }
      });

      const errorMessage = errorMessages.map(i => `<li>${i}</li>`).join('');
      const ulStyle =
        'display: table; margin-left: auto; margin-right: auto; text-align: left';
      if (errors.length > 0) {
        this.$modal.show({
          title: title,
          html: `<ul style="${ulStyle}">${errorMessage}</ul>`,
          type: 'primary',
        });
        return;
      } else {
        this.$modal.show({
          title: title,
          html: message,
          type: 'primary',
        });
      }
    },
    toggleSelection(rows: any[]): any {
      if (rows) {
        rows.forEach(row => {
          if (row.status === 'USABLE') {
            const serialTable = this.$refs.serialsTable as any;
            serialTable.toggleRowSelection(row);
          }
        });
      } else {
        const serialTable = this.$refs.serialsTable as any;
        serialTable.clearSelection();
      }
    },
    handleSelectionChange(items: any[]): void {
      this.selection = items;
    },
    async pageClick(bvEvent: any, page: number) {
      await this.$router
        .push({
          path: `/serial/bundle/serials/${this.$route.params.bundleId}`,
          // page: page,
          // size: this.size,
          query: {
            serialNos: this.$route.query.serialNos,
          },
        })
        .catch(() => {});
    },
    stateColor(state: string) {
      if (state === 'ACTIVE') {
        return 'success';
      } else if (state === 'INACTIVE') {
        return 'warning';
      } else {
        return 'info';
      }
    },
    typeColor(type: string) {
      if (type === 'POINT') {
        return 'info';
      }
      return 'secondary';
    },
    convertedStatus(status: string): string {
      if (status === 'USABLE') {
        return '사용가능';
      } else if (status === 'USED') {
        return '사용됨';
      } else if (status === 'DELETED') {
        return '삭제됨';
      } else {
        return '알수없음';
      }
    },
    convertedUsed(used: boolean): string {
      if (used) {
        return '사용됨';
      } else {
        return '미사용';
      }
    },
    async deleteSerial(row: string) {
      this.$modal.show(
        {
          title: '난수를 삭제합니다.',
          html: '난수가 삭제되면 사용자가 사용할 수 없습니다.',
          showCancelButton: true,
          type: 'primary',
        },
        async () => {
          try {
            const serials: string[] = [];
            if (row.trim() != undefined) {
              serials.push(row.trim());
            }
            const failedSerialNos = await serialService.deleteSerials(serials);

            if (failedSerialNos.length > 0) {
              const message = failedSerialNos as string;
              this.$modal.show({
                title: '난수가 삭제되지 않았습니다.',
                message: message,
                type: 'danger',
              });
            } else {
              this.$data.items
                .filter((item: { serialNo: string }) => item.serialNo === row)
                .map((item: { status: string }) => (item.status = 'DELETED'));
            }
          } catch (e) {
            this.showErrorAlert(e, '난수 삭제 실패');
          }
        }
      );
    },
    async deleteSerials() {
      if (this.selection.length === 0) {
        this.showAlert({
          message: '선택한 난수가 없습니다.',
          type: 'danger',
        });
        return;
      }
      this.$modal.show(
        {
          title: '선택한 난수를 삭제합니다.',
          html: '선택한 난수가 삭제되면 사용자가 사용할 수 없습니다.',
          showCancelButton: true,
          type: 'danger',
        },
        async () => {
          try {
            const serials: string[] = [];
            this.selection.forEach(item => {
              serials.push(item.serialNo.toString().trim());
            });

            const failedSerialNos = await serialService.deleteSerials(serials);

            const updatedSerialNos = serials.filter(
              item => !failedSerialNos.includes(item)
            );

            updatedSerialNos.forEach(serialNos => {
              this.$data.items
                .filter(
                  (item: { serialNo: string }) => item.serialNo === serialNos
                )
                .map((item: { status: string }) => (item.status = 'DELETED'));
            });
            const message = failedSerialNos as string;
            if (failedSerialNos.length > 0) {
              this.$modal.show({
                title: '일부 난수가 삭제되지 않았습니다.',
                message: message,
                type: 'danger',
              });
            }
          } catch (e) {
            this.showErrorAlert(e, '난수 삭제 실패');
          }
        }
      );
    },
  },
  apollo: {
    serial: {
      query: BUNDLE_SERIALS_QUERY,
      variables(): {
        page: number;
        pageSize: number;
        bundleId: number;
        serialNos: string[];
      } {
        return {
          page: Number(this.$data.page),
          pageSize: Number(this.$data.size),
          bundleId: Number(this.$route.params.bundleId),
          serialNos: this.$route.query.serialNos as string[],
        };
      },
      error(e: ApolloError) {
        console.error(`SeiralBundleSerialsError: `, e);
      },
      result(
        result: ApolloQueryResult<{
          serial: { serialsBySerialNos: SerialConnection };
        }>
      ) {
        this.$data.items = result.data.serial.serialsBySerialNos.edges.map(
          (edge: SerialEdge) => edge.node
        );
        this.$data.totalRows = result.data.serial.serialsBySerialNos.totalCount;
      },
      update: (data: { serial: { serialsBySerialNos: Connection<Serial> } }) =>
        data.serial.serialsBySerialNos.edges.map(edge => edge.node),
    },
  },
});
