import {
  BundlePolicyTypeId,
  CreateBundleParams,
  CreateSerialPointPolicyParams,
  PointExpirationMethod,
  PointPolicyType,
  SerialBundleType,
  UpdateBundleParams,
} from '../model/serial';

interface PolicyForm {
  name: string;
  description: string;
  point: number;
  type: PointPolicyType;
  expirationMethod: PointExpirationMethod;
  expiresAfter: number;
  expiresAt?: number;
  bonusPoint: number;
  usablePeriod: {
    startedAt?: number;
    endedAt?: number;
  };
}

export const convertToPolicyParams = (
  form: PolicyForm
): CreateSerialPointPolicyParams => {
  const param: CreateSerialPointPolicyParams = {
    name: form.name,
    description: form.description,
    point: Number(form.point),
    type: form.type,
    expirationMethod: form.expirationMethod,
    expiresAfter: Number(form.expiresAfter),
    expiresAt: form.expiresAt ? Number(form.expiresAt) : null,
    bonusPoint: Number(form.bonusPoint),
    startAt: form.usablePeriod.startedAt
      ? Number(form.usablePeriod.startedAt)
      : null,
    endAt: form.usablePeriod.endedAt ? Number(form.usablePeriod.endedAt) : null,
  };

  return param;
};

interface BundleForm {
  id?: string;
  title: string;
  description: string;
  count: number;
  type: SerialBundleType;
  targetId: string;
  policyIds: BundlePolicyTypeId[];
}

export const convertToCreateBundleParams = (
  form: BundleForm
): CreateBundleParams => {
  const param: CreateBundleParams = {
    title: form.title,
    description: form.description,
    count: Number(form.count),
    type: form.type,
    targetId: form.targetId,
    policyIds: form.policyIds,
  };

  return param;
};

export const convertToUpdateBundleParams = (
  form: BundleForm
): UpdateBundleParams => {
  return {
    id: form.id,
    policyIds: form.policyIds,
  } as UpdateBundleParams;
};
