var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading > 0)?_c('spinner'):_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center mb-0",staticStyle:{"text-align":"center"},attrs:{"header-row-class-name":"thead-light","data":_vm.exportList}},[_c('el-table-column',{attrs:{"label":"id","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.node.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"생성 시점","prop":"completedAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('instant',{attrs:{"at":row.node.completedAt}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"요청자","prop":"requester"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.node.requester.nickname)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"상태","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.node.status)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"csv 파일 다운로드","prop":"download"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.node.status === 'CREATED')?_c('frip-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.getDownloadUrl(row.node.id)}}},[_vm._v(" download ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }