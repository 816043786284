var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"no-body":"","shadow-size":"0"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.size,"align":"center","first-number":"","last-number":""},on:{"page-click":_vm.pageClick},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}])},[_c('b-card-header',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"1.2rem"}},[_vm._v(" 검색 결과: "+_vm._s(_vm.totalRows.toString()))]),_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-sm btn-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleSelection(_vm.items)}}},[_vm._v(" 삭제가능 난수선택 ")]),_c('button',{staticClass:"btn btn-sm btn-info",attrs:{"type":"button"},on:{"click":_vm.deleteSerials}},[_vm._v(" 선택한 난수삭제 ")])])]),(_vm.loading > 0)?_c('spinner'):_c('el-table',{ref:"serialsTable",staticClass:"table table-responsive table-flush table-striped align-items-center mb-0",staticStyle:{"text-align":"center"},attrs:{"header-row-class-name":"thead-light","header-cell-class-name":"center-table","cell-class-name":"text-center","data":_vm.items,"header-row-style":{ textAlign: 'center' }},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"80","class-name":"center-table"}}),_c('el-table-column',{attrs:{"label":"난수","prop":"serialNo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.serialNo)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"제목","prop":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.bundle.title)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"난수상태(사용가능/삭제)","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.convertedStatus(row.status))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"사용여부","prop":"used"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.convertedUsed(row.used))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"크루ID","prop":"userId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.usedBy === null ? '-' : row.usedBy.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"크루 닉네임","prop":"nickname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.usedBy === null ? '-' : row.usedBy.nickname)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"사용일시","prop":"usedAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.usedAt !== null)?_c('instant',{attrs:{"at":row.usedAt}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"난수삭제","prop":"removeAndPaste","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 'USABLE')?_c('frip-button',{staticClass:"my-1",attrs:{"outline":"","size":"sm"},on:{"click":function($event){return _vm.deleteSerial(row.serialNo)}}},[_vm._v("난수삭제")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }