














































import Vue from 'vue';
import Spinner from '@/components/Spinner.vue';
import PolicyForm from '../components/PolicyForm.vue';
import { serialService } from '../service/serialService';
import { SerialPointPolicy } from '../model/serial';
import { POLICY_QUERY } from '../queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { convertToPolicyParams } from '@/domain/serial/util/serialConverter';

export default Vue.extend({
  name: 'BundleDetail',
  components: {
    Spinner,
    PolicyForm,
  },
  data() {
    return {
      policyId: 0,
      loading: 0,
      policy: {
        name: '',
        description: '',
        price: 0,
        point: 0,
        type: 'POINT',
        expirationMethod: 'DAYS',
        expiresAfter: null,
        expiresAt: null,
        bonusPoint: null,
        usablePeriod: {
          startedAt: '',
          endedAt: '',
        },
      },
      showErrorMessageTime: 0,
      errorMessage: '',
    };
  },
  computed: {
    policyDetailTitle() {
      if (this.policyId > 0) {
        return this.$data.policy.name;
      } else {
        return '신규 에너지 일련번호 정책 등록';
      }
    },
  },
  methods: {
    showErrorMessage(message: string) {
      this.$data.showErrorMessageTime = 5;
      this.$data.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number) {
      this.$data.showErrorMessageTime = showErrorMessageTime;
    },
    async createPolicy() {
      try {
        console.log(this.$data.policy);
        await serialService.createPolicy(
          convertToPolicyParams(this.$data.policy)
        );
        this.$router.push('/serial/policy/list');
      } catch (error) {
        console.error(error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).showErrorMessage(error.message);
      }
    },
    async deactivate() {
      if (confirm('정말로 비활성화 하시겠습니까?')) {
        try {
          await serialService.deactivatePolicy(this.$route.params.id);
          this.$router.push('/serial/policy/list');
          console.log('deactivate');
        } catch (error) {
          console.error(error);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this as any).showErrorMessage(error.message);
        }
      }
    },
  },
  apollo: {
    serial: {
      query: POLICY_QUERY,
      variables() {
        return {
          id: this.$route.params.id || 0,
        };
      },
      error(error: ApolloError) {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      result(
        result: ApolloQueryResult<{
          serial: { pointPolicy: SerialPointPolicy };
        }>
      ) {
        const policy = result.data?.serial.pointPolicy;
        console.log('policy:');
        console.log(policy);
        if (policy) {
          this.$data.policyId = policy.id;
          this.$data.policy.name = policy.name;
          this.$data.policy.description = policy.description;
          this.$data.policy.point = policy.point;
          this.$data.policy.type = policy.type;
          this.$data.policy.expirationMethod = policy.pointExpirationMethod;
          this.$data.policy.expiresAfter = policy.expiresAfter;
          this.$data.policy.expiresAt = policy.expiresAt;
          this.$data.policy.bonusPoint = policy.bonusPoint;
          this.$data.policy.usablePeriod.startedAt = policy.startAt;
          this.$data.policy.usablePeriod.endedAt = policy.endAt;
        }
      },
      skip() {
        return !this.$route.params.id;
      },
    },
  },
});
