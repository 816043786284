import { CommonState, PageInfo } from '@frientrip/domain';

export interface Serial {
  serialNo: string;
  bundleId: number;
  status: string;
  used: boolean;
  usedById?: number;
  usedAt?: Date;
}

/* ---------- SerialBundle ---------- */
export interface BundleEdge {
  node: SerialBundle;
  cursor: string;
}

export interface BundleConnection {
  edges: BundleEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface SerialBundle {
  id: number;
  title: string;
  count: number;
  type: string;
  targetId: string;
  status: string;
  description: string;
  policyIds: BundlePolicyTypeId[];
}

export enum BundlePolicyTypeId {
  MARKETING_AGREEMENT_USER = 'MARKETING_AGREEMENT_USER',
  PLATFORM_AOS = 'PLATFORM_AOS',
  PLATFORM_IOS = 'PLATFORM_IOS',
  PLATFORM_WEB = 'PLATFORM_WEB',
  PLATFORM_MOBILE = 'PLATFORM_MOBILE',
  NO_ORDERED_USER = 'NO_ORDERED_USER',
}

export interface CreateBundleParams {
  title: string;
  count: number;
  type: SerialBundleType;
  targetId: string;
  description: string;
  policyIds: BundlePolicyTypeId[];
}

export interface UpdateBundleParams {
  id: string;
  policyIds: BundlePolicyTypeId[];
}

export enum PointPolicyType {
  POINT = 'POINT',
  POINT_B2B = 'POINT_B2B',
}

export enum SerialBundleType {
  COUPON = 'COUPON',
  POINT = 'POINT',
}

/* ---------- PointPolicy ---------- */
export interface PolicyConnection {
  edges: PolicyEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface PolicyEdge {
  node: SerialPointPolicy;
  cursor: string;
}

export interface SerialPointPolicy {
  id: number;
  name: string;
  description: string;
  point: number;
  type: PointPolicyType;
  status: CommonState;
  pointExpirationMethod: PointExpirationMethod;
  expiresAfter?: number;
  expiresAt?: Date;
  bonusPoint?: number;
  startAt?: Date;
  endAt?: Date;
}

export interface CreateSerialPointPolicyParams {
  name: string;
  description: string;
  point: number;
  type: PointPolicyType;
  expirationMethod: PointExpirationMethod;
  expiresAfter?: number;
  expiresAt?: number | null;
  bonusPoint?: number;
  startAt?: number | null;
  endAt?: number | null;
}

export enum PointExpirationMethod {
  DAYS = 'DAYS',
  UNTIL = 'UNTIL',
}

/* ---------- Exports ---------- */
export interface ExportConnection {
  edges: ExportEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface ExportEdge {
  node: SerialExport;
  cursor: string;
}

export interface SerialExport {
  id: number;
  requester: User;
  status: string;
  completedAt: Date;
}

export interface User {
  id: number;
  nickname: string;
}

export interface SerialConnection {
  edges: SerialEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface SerialEdge {
  cursor: string;
  node: Serial;
}
