var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"notification":"이름을 누르면 에너지 일련번호 정책으로 이동합니다.","title":"에너지 일련번호 정책 리스트","subtitle":_vm.totalRows.toString()},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.size,"align":"center","first-number":"","last-number":""},on:{"page-click":_vm.pageClick},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('b-card-footer',[_c('b-row',{staticClass:"float-right mx-3"},[_c('frip-button',{attrs:{"type":"primary","to":"./create"}},[_vm._v("정책 등록")])],1)],1)]},proxy:true}])},[(_vm.loading > 0)?_c('spinner'):_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center mb-0",staticStyle:{"text-align":"center"},attrs:{"header-row-class-name":"thead-light","data":_vm.items}},[_c('el-table-column',{attrs:{"label":"id","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"이름","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":("list/" + (row.id))}},[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"부가 설명","prop":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.description)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"에너지","prop":"point"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.point)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"만료 방식","prop":"expMethod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.pointExpirationMethod)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"유효 기간","prop":"expiration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.pointExpirationMethod === 'DAYS')?_c('div',[_vm._v(" "+_vm._s(row.expiresAfter)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"만료일","prop":"expiration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.pointExpirationMethod === 'UNTIL')?_c('div',[_c('instant',{attrs:{"at":row.expiresAt,"short":""}})],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"보너스 에너지","prop":"bonus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.bonusPoint)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"상태","prop":"state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-badge',{attrs:{"type":_vm.stateColor(row.status)}},[_vm._v(" "+_vm._s(row.status)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }