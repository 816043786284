

























import { ContainerMixin } from '@/common/mixin/containerMixin';
import { Option, Select } from 'element-ui';
import FripButton from '@/components/FripComponents/FripButton.vue';
import Icon from '@/components/Icon/Icon.vue';
import HostSearchInput from '@/components/Host/HostSearchInput.vue';
import VueRouter from 'vue-router';

export default ContainerMixin.extend({
  name: 'BundleSerialsSearchForm',
  components: {
    HostSearchInput,
    [Select.name]: Select,
    [Option.name]: Option,
    FripButton,
    Icon,
  },
  data() {
    let searchTarget = 'serialNos';
    let searchKeyword = '';

    return {
      totalCount: 0,
      searchTarget: searchTarget,
      searchSerialNos: searchKeyword,
    };
  },
  computed: {
    serialNos() {
      const query = this.$route.query.serialNos as string[];
    },
  },
  methods: {
    clear() {
      this.searchSerialNos = '';
      this.$router.push({ query: undefined }).catch();
    },
    onClickSearch() {
      if (this.searchSerialNos.trim().length == 0) {
        alert('검색어를 입력하세요.');
        return;
      }

      if (this.searchSerialNos.trim().length > 220) {
        alert('검색어를 220자 이내(20건이내)로 입력하세요.');
        return;
      }

      const trimedSerialNos = this.searchSerialNos
        .trim()
        .split(',')
        .map(i => i.trim());

      this.$router
        .push({
          name: 'BundleSerialsListContainer',
          query: { serialNos: trimedSerialNos },
        })
        .catch(err => {
          console.error(err);
          if (
            VueRouter.isNavigationFailure(
              err,
              VueRouter.NavigationFailureType.duplicated
            )
          ) {
            this.$emit('refresh', true);
          }
        });
    },
  },
});
