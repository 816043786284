import gql from 'graphql-tag';

export const CREATE_BUNDLE_MUTATION = gql`
  mutation createBundle($param: CreateSerialBundleParams!) {
    createSerialBundle(param: $param) {
      id
      count
      targetId
      type
      status
    }
  }
`;

export const UPDATE_BUNDLE_MUTATION = gql`
  mutation updateBundle($param: UpdateSerialBundleParams!) {
    updateSerialBundle(param: $param) {
      id
      count
      targetId
      type
      status
    }
  }
`;

export const DEACTIVATE_BUNDLE_MUTATION = gql`
  mutation deactivateBundle($id: ID!) {
    deactivateSerialBundle(id: $id) {
      id
      title
      status
    }
  }
`;

export const CREATE_POLICY_MUTATION = gql`
  mutation createPolicy($param: CreateSerialPointPolicyParams!) {
    createSerialPointPolicy(param: $param) {
      id
      name
      status
    }
  }
`;

export const DEACTIVATE_POLICY_MUTATION = gql`
  mutation deactivatePolicy($id: ID!) {
    deactivateSerialPointPolicy(id: $id) {
      id
      name
      status
    }
  }
`;

export const EXPORT_SERIAL_MUTATION = gql`
  mutation exportSerials($id: ID!) {
    exportSerials(bundleId: $id) {
      id
      bundle {
        id
        title
      }
      status
      requester {
        id
      }
      fileUrl
      completedAt
    }
  }
`;

export const DELETE_SERIALS_MUTATION = gql`
  mutation deleteSerials($serialNos: [String!]!) {
    deleteSerials(serialNos: $serialNos) {
      serialNo
    }
  }
`;
