







import Vue from 'vue';
import BundleSerialsSearchForm from '../components/BundleSerialsSearchForm.vue';
import BundleSerialsList from '../components/BundleSerialsList.vue';

export default Vue.extend({
  name: 'BundleSerialsListContainer',
  components: {
    BundleSerialsList,
    BundleSerialsSearchForm,
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
