






















































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { BUNDLE_LIST_QUERY } from '../queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { BundleConnection, BundleEdge } from '../model/serial';
import Spinner from '@/components/Spinner.vue';

export default Vue.extend({
  name: 'SerialBundleList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
  },
  data: function () {
    return {
      items: [],
      columns: ['id', 'title', 'type', 'status'],
      loading: 0,
      page: this.$route.query.page || 1,
      size: this.$route.query.size || 10,
      totalRows: 0,
    };
  },
  methods: {
    pageClick(bvEvent: any, page: number) {
      if (
        this.$route.path !== `/bundle/list?page=${page}&size=${this.$data.size}`
      ) {
        this.$router.push(
          `/serial/bundle/list?page=${page}&size=${this.$data.size}`
        );
      }
    },
    stateColor(state: string) {
      if (state === 'ACTIVE') {
        return 'success';
      } else if (state === 'INACTIVE') {
        return 'warning';
      } else {
        return 'info';
      }
    },
    typeColor(type: string) {
      if (type === 'POINT') {
        return 'info';
      }
      return 'secondary';
    },
  },
  apollo: {
    serial: {
      query: BUNDLE_LIST_QUERY,
      variables() {
        return {
          page: Number(this.$data.page),
          pageSize: Number(this.$data.size),
        };
      },
      error(e: ApolloError) {
        console.error(`SeiralBundleListError: `, e);
      },
      result(
        result: ApolloQueryResult<{ serial: { bundles: BundleConnection } }>
      ) {
        this.$data.items = result.data.serial.bundles.edges.map(
          (edge: BundleEdge) => edge.node
        );
        this.$data.totalRows = result.data.serial.bundles.totalCount;
      },
    },
  },
});
