

























































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { POLICY_LIST_QUERY } from '../queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { PolicyConnection, PolicyEdge } from '../model/serial';
import Spinner from '@/components/Spinner.vue';
import Instant from '@/components/Labels/Instant.vue';

export default Vue.extend({
  name: 'PointPolicyList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
    Instant,
  },
  data: function () {
    return {
      items: [],
      // columns: ['id', 'name', 'description', 'state'],
      loading: 0,
      page: this.$route.query.page || 1,
      size: this.$route.query.size || 10,
      totalRows: 0,
    };
  },
  methods: {
    pageClick(bvEvent: any, page: number) {
      console.log('page clicked!' + page);
      if (
        this.$route.path !== `/policy/list?page=${page}&size=${this.$data.size}`
      ) {
        this.$router.push(
          `/serial/policy/list?page=${page}&size=${this.$data.size}`
        );
      }
    },
    stateColor(state: string) {
      if (state === 'ACTIVE') {
        return 'success';
      } else if (state === 'INACTIVE') {
        return 'warning';
      } else if (state === 'DELETED') {
        return 'secondary';
      }
    },
  },
  apollo: {
    serial: {
      query: POLICY_LIST_QUERY,
      variables() {
        return {
          page: Number(this.$data.page),
          pageSize: Number(this.$data.size),
        };
      },
      error(e: ApolloError) {
        console.error(`SeiralPolicyListError: `, e);
      },
      result(
        result: ApolloQueryResult<{
          serial: { pointPolicies: PolicyConnection };
        }>
      ) {
        this.$data.items = result.data.serial.pointPolicies.edges.map(
          (edge: PolicyEdge) => edge.node
        );
        this.$data.totalRows = result.data.serial.pointPolicies.totalCount;
      },
    },
  },
});
