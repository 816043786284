
















































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import Instant from '@/components/Labels/Instant.vue';
import { serialService } from '../service/serialService';

export default Vue.extend({
  name: 'BundleExportList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
    Instant,
  },
  props: {
    exportList: {
      type: Array,
    },
  },
  data() {
    return {
      loading: 0,
    };
  },
  methods: {
    showErrorMessage(message: string) {
      this.$data.showErrorMessageTime = 5;
      this.$data.errorMessage = message.replace('GraphQL error:', '');
    },
    async getDownloadUrl(id: string) {
      try {
        const url = await serialService.downloadFile(id);
        console.log(url);
        window.open(url, '_blank');
      } catch (error) {
        console.error(error);
        this.showErrorMessage(error.message);
      }
    },
  },
});
